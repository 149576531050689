import { AuthLoginScreen } from 'iit-ui';
import { useRouter } from 'next/router';
import { getServerSession } from 'next-auth';
import { getCsrfToken, signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { getServerTranslations } from '@/lib/getServerTranslations';
import { fetcher, getFirstItem } from '@/lib/helpers';
import { authOptions } from '@/pages/api/auth/[...nextauth]';
import { LoginProvider } from '@/types/auth';
import useAlert from '@/utils/alert/use-alert';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';

export default function SignInPage({ csrfToken }: InferGetServerSidePropsType<typeof getServerSideProps>) {
	const { t } = useTranslation(['common', 'unauthorized']);
	const router = useRouter();
	const { setAlert } = useAlert();

	const error = getFirstItem(router.query.error);

	const [providers, setProviders] = useState<LoginProvider[] | undefined>();

	useEffect(() => {
		fetcher('/api/providers/get', setAlert, t('error-get-providers')).then((response: LoginProvider[] | undefined) => setProviders(response ?? []));
	}, []);

	useEffect(() => {
		if (error) {
			let customErrorMsg = '';

			switch (error) {
				case 'Unauthorized':
					customErrorMsg = t('error-unauthorized');
					break;
				default:
					customErrorMsg = error;
					break;
			}

			setAlert('info', customErrorMsg);
		}
	}, [error]);

	const LoginHeader = <h2 className="text-3xl font-bold font-caveat text-center">{t('unauthorized:login-title')}</h2>;

	return (
		<div className="flex flex-col grow gap-4 self-stretch justify-center items-center relative">
			<AuthLoginScreen
				providers={providers}
				signIn={signIn}
				csrfToken={csrfToken}
				t={t}
				title={LoginHeader}
				loadingMessage={t('unauthorized:loading-providers')}
			/>
		</div>
	);
}

SignInPage.getLayoutProps = {
	back: false,
	logout: false,
	fullPage: true,
};
export async function getServerSideProps(context: GetServerSidePropsContext) {
	const session = await getServerSession(context.req, context.res, authOptions);

	if (session) {
		return {
			redirect: { destination: '/' },
		};
	}
	return {
		props: {
			csrfToken: await getCsrfToken(context),
			...(await getServerTranslations(context.locale, ['common', 'unauthorized'])),
		},
	};
}
